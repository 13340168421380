/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */


'use strict';
angular.module('matroshkiApp').controller('modelListCtrl', ['$scope', '$timeout', function ($scope, $timeout) {
  $scope.performers = [];
  $scope.categories = [];
  $scope.initList = function(performers, categories) {
    $scope.performers = performers.data;
    $scope.categories = categories;
  }
}])