/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */


'use strict';
angular.module('matroshkiApp').controller('modelGalleryListCtrl', ['$scope', '$timeout', function ($scope, $timeout) {
  $scope.videos = [];
  $scope.initList = function(galleries) {
    $scope.galleries = galleries.data;
    $scope.animation();
  };

  $scope.animation = function() {
    $timeout(() => {
      $('.image-galleries .thumbnail').hover(
        function () {
          $(this).find('.caption').slideDown(250); //.fadeIn(250)
        },
        function () {
          $(this).find('.caption').slideUp(250); //.fadeOut(205)
        })}, 50);
  }
}])